import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectDirection } from 'store/globalSlice';
import { isRtl } from 'common/utils/utils';
import { StyledToggleButton } from './styled';
import { ReactComponent as ChevronRightIcon } from '../../images/icons/chevronRight.svg';
import { ReactComponent as ChevronLeftIcon } from '../../images/icons/chevronLeft.svg';

export default function ToggleButton(props) {
  const { leftChevron, toggle, isNavBarCollapsed, className, isDisplay = true, ...rest } = props;
  const direction = useSelector(selectDirection);
 const isRTL = isRtl(direction);
  const chevron = useMemo(() =>
      leftChevron === isRTL ? <ChevronRightIcon /> : <ChevronLeftIcon />,
    [leftChevron, isRTL]
  );
  return (
    isDisplay && (
      <StyledToggleButton {...rest} onClick={toggle} isNavBarCollapsed={isNavBarCollapsed} className={className}>
        {chevron}
      </StyledToggleButton>
    )
  );
}
