import { createSlice } from '@reduxjs/toolkit';
import { SR_CODE_TYPE_MAP } from 'features/TicketPanel/constants';

const initialState = {
  template: null,
  srType: null
};

export const templateSlice = createSlice({
  name: 'templateBuilder',
  initialState,
  reducers: {
    setBuilderTemplate: (state, { payload }) => {
      state.template = payload;
      state.srType = SR_CODE_TYPE_MAP[payload?.srType]
    },
    updateBuilerTemplate: (state, { payload }) => {
      state.template = {
        ...state.template,
        ...payload,
      };
    },
  },
  selectSlice: (state) => state.templateBuilder,
  selectors: {
    selectTemplate: (state) => state?.template || {},
    selectTemplateSections: (state) => state.template?.sections,
    selectTemplateHeader: (state) => state.template?.header,
    selectTemplateSectionsOrder: (state) => state.template?.sectionsOrder,
    selectTemplateDependantFields: (state) => state.template?.dependantFields,
    selectTemplateSRType: (state) => state.srType
  },
});

export const { setBuilderTemplate, updateBuilerTemplate } = templateSlice.actions;

export const {
  selectTemplate,
  selectTemplateSections,
  selectTemplateHeader,
  selectTemplateSectionsOrder,
  selectTemplateDependantFields,
  selectTemplateSRType
} = templateSlice.selectors;

export default templateSlice.reducer;
export const templateBuilderSliceName = templateSlice.name;
